@font-face {
  font-family: 'nasalization';
  src: url(./assets/fonts/nasalization.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'della-respira';
  src: url(./assets/fonts/della-respira.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'della-respira';
  src: url(./assets/fonts/della-respira.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'sorts-mill-goudy';
  src: url(./assets/fonts/sorts-mill-goudy.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'volkorn';
  src: url(./assets/fonts/volkorn.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: della-respira, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, della-respira, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100%;
  color: #c9cacc;
  background: #1d1f21;
}

.ReactModal__Overlay {
    z-index: 2;
}
